import React, { useState, useEffect } from 'react';
import { Camera, PawPrint, Info } from 'lucide-react';
import Hls from 'hls.js';

const SingleCameraStream = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [streamName, setStreamName] = useState('');
  const [wsReady, setWsReady] = useState(false);  // Track if WebSocket is ready

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const streamId = urlParams.get('id');
    const name = urlParams.get('name');

    if (!streamId) {
      setError('No stream ID provided. Please check the URL.');
      setIsLoading(false);
      return;
    }

    setStreamName(name || `Camera ${streamId}`);

    const video = document.getElementById('videoPlayer');
    const ws = new WebSocket('ws://159.89.84.73:8765');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      setWsReady(true);  // Mark WebSocket as ready
      setError(null);  // Clear any previous error
      ws.send(JSON.stringify({ action: 'watch', stream_id: streamId }));
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error: ', error);
      setError('Failed to connect to the live stream. Please try again later.');
      setIsLoading(false);
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed.');
      setWsReady(false);  // Mark WebSocket as not ready
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received WebSocket message:', data);

      if (data.status === 'streaming') {
        const videoUrl = `http://159.89.84.73${data.url}`;
        console.log('Stream URL received:', videoUrl);

        if (Hls.isSupported()) {
          console.log('HLS.js is supported.');
          const hls = new Hls();
          hls.loadSource(videoUrl);
          hls.attachMedia(video);
          hls.on(Hls.Events.ERROR, (event, data) => {
            console.error('HLS.js Error:', data);
            if (data.fatal) {
              setError('Stream error. Please try again later.');
              setIsLoading(false);
            }
          });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          console.log('Native HLS support detected.');
          video.src = videoUrl;
          video.play().catch((e) => {
            console.error('Autoplay failed:', e);
            setError('Failed to play the stream. Please try again.');
          });
        } else {
          setError('Your browser does not support HLS.');
        }

        setIsLoading(false); // Stop showing the loading spinner when streaming
      } else {
        setError('Failed to start the live stream.');
      }
    };

    // Only send "stop" action if the WebSocket is ready when closing the connection
    return () => {
      if (wsReady) {
        ws.send(JSON.stringify({ action: 'stop', stream_id: streamId }));
      }
      ws.close();
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-green-100 p-4 sm:p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <header className="bg-blue-600 text-white p-4 sm:p-6">
          <h1 className="text-2xl sm:text-3xl font-bold flex items-center">
            <PawPrint className="mr-2" />
            Adirondack Pet Lodge: {streamName}
          </h1>
        </header>

        <main className="p-4 sm:p-6">
          <div className="relative">
            <video
              id="videoPlayer"
              className="w-full h-auto rounded-lg shadow-md"
              controls
              autoPlay
              muted
            ></video>
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                <div className="text-white text-xl">Loading stream...</div>
              </div>
            )}
          </div>

          {error && (
            <div className="mt-4 p-4 bg-red-200 text-red-800 rounded-lg">
              <strong>Error:</strong> {error}
            </div>
          )}

          <div className="mt-6 bg-blue-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold flex items-center mb-2">
              <Info className="mr-2" /> About This Live Stream
            </h3>
            <p>
              Welcome to the {streamName} live stream at Adirondack Pet Lodge. This stream allows you to
              check in on your furry friends anytime, anywhere. Enjoy watching your pets as they play, rest, and enjoy
              their stay with us!
            </p>
          </div>
        </main>

        <footer className="bg-gray-100 text-center p-4 text-sm text-gray-600">
          © 2024 Adirondack Pet Lodge 
        </footer>
      </div>
    </div>
  );
};

export default SingleCameraStream;
