import React from 'react';
import SingleCameraStream from './SingleCameraStream';

function App() {
  return (
    <div className="App">
      <SingleCameraStream />
    </div>
  );
}

export default App;
